import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Hub } from 'aws-amplify/utils';
import { StairazAuth } from '../components/userAuth.js';
import awsmobile from '../aws-exports.js';
import { Amplify} from 'aws-amplify';
import { useTheme } from '../context/ThemeContext';
const isLocalhost = process.env.REACT_APP_ENV === 'development';

  


  
Amplify.configure(awsmobile);




const RedirectPage = () => {
  const { isDarkMode } = useTheme();
  useEffect(() => {
    // Retrieve the URL to redirect to
    const redirectUrl = localStorage.getItem("preAuthPath") || "/";
  
    // Clear the stored URL
    localStorage.removeItem("preAuthPath");
    
    // Redirect to the retrieved URL or to the home page
    navigate(redirectUrl);
  }, []);
  

  return (
    <div style={{backgroundColor:isDarkMode?'black':'white',color:isDarkMode?'white':'black'}}>
      <p>Redirecting...</p>
    </div>
  );
};

export default StairazAuth(RedirectPage);
